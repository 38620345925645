<template>
  <div class="protocol-box">
    <h2 style="text-align: center; margin-bottom: 0.3rem">{{ appName }}VIP会员服务协议</h2>
    <h4>版本更新提示</h4>
    <p>更新日期：【2021】年【11】月【2】日</p>
    <p>尊敬的用户：</p>
    <p>
      您好！为了向您提供更优质、贴心的服务。近日，{{ companyAlias }}依据最新出台的法律法规相关规定以及公司自身产品经营规划，对《{{ appName }}VIP会员服务协议》进行了更新。本版本主要增加了未成年使用条款、细化了付费及退费规则的约定、对《{{ appName }}VIP会员服务协议》以及《{{ appName }}用户协议》的关联及条款适用进行了具体说明，同时还对部分条款的表述进行优化，使之约定的权利义务内容更加清晰，以便各方遵照执行。
    </p>
    <h4>发布日期：【2021】年【11】月【2】日</h4>
    <h4>生效日期：【2021】年【11】月【2】日</h4>
    <h4>第【2】版</h4>
    <p></p>
    <h3>1.重要须知</h3>
    <p>
      1.1 在使用{{ appName }}VIP会员服务（简称为：“本服务”）前，请仔细阅读、理解并遵守《{{ appName }}VIP会员服务协议》（简称为：本协议）。
    </p>
    <p>
      <b>
        1.2
        本协议由您（指购买及使用“{{ appName }}”平台{{ appName }}VIP服务的注册用户，亦称“会员”“{{ appName }}VIP会员”）与成都开心音符科技有限公司及关联公司（简称为：“{{ companyAlias }}”）共同缔结。在您同意本协议后，您将享有本协议约定的{{ appName }}VIP会员权益。
      </b>
    </p>
    <p>
      <b>
        1.3
        {{ companyAlias }}以黑体加粗标记或其他合理方式提示您注意，并请您重点阅读本协议中可能与您的权益有重大利害关系的条款，包括但不限于相关免除{{ companyAlias }}责任的条款（以下称：免责条款）、对用户权利进行限制的条款（以下称：限制条款）、约定争议解决方式和司法管辖的条款等。如果您认为该等条款可能导致您在特定情况下部分或全部利益或权利受损，请您在确认同意本协议之前或在使用本服务之前务必再次阅读前述条款，并在您自愿接受该等条款的前提下使用本服务。
      </b>
    </p>
    <p>
      <b>
        1.4
        如果您不同意本协议的任意或全部条款内容，尤其是前述可能与您的权益存在重大利害关系的条款，请不要以接受本服务的形式（包括但不限于进入购买程序、进行或参与任何与本服务相关的交易或活动、采用任何其他形式的确认操作，下统称：接受服务的形式）进行下一步操作或使用本服务。当您采用接受服务的形式进行下一步操作或使用本服务，即表明您已阅读、理解并同意签署本协议，表示您与{{ companyAlias }}已达成协议关系，并自愿接受并遵守本协议的全部约定。
      </b>
    </p>
    <p>
      <b>
        1.5
        本协议为《{{ appName }}用户协议》的有效组成部分，本协议未约定之内容以《{{ appName }}用户协议》约定为准，本协议约定内容与《{{ appName }}用户协议》存在冲突之处，{{ appName }}VIP会员应遵守本协议之约定。本协议内容同时包括{{ appName }}已经或在未来不时发布的关于本协议的相关协议、业务规则、附加条款、产品要求等内容。上述内容一经正式发布即生效，为本协议不可分割的组成部分。
      </b>
    </p>
    <h3>2.会员账号管理及使用规则</h3>
    <h4>2.1 会员注册</h4>
    <p>
      （1）在成为会员之前，您应按照《{{ appName }}用户协议》相关规定注册成为“{{ appName }}”平台用户。您应当以真实、准确信息注册成为“{{ appName }}”平台用户，否则{{ companyAlias }}有权拒绝您申请或撤销其会员资格，有关会员费用将按照您的实际使用会员VIP会员权益情况折算后，将剩余会员费（如有）退还到您的账户中；若因您的前述行为导致“{{ appName }}”平台遭受损失的，{{ companyAlias }}有权要求您承担相应赔偿责任。{{ companyAlias }}有权从会员费中扣除有关赔偿费用，若不足以弥补{{ companyAlias }}损失的，{{ companyAlias }}还有权要求您补足。
    </p>
    <p>
      （2）若您的个人资料发生变化，您应及时在“{{ appName }}”平台中修改相应资料，否则由此造成您的{{ appName }}VIP会员权利不能全面有效行使，相关的责任由您自己承担。
    </p>
    <p>
      （3）您可通过各种已有和未来新增的渠道成为{{ appName }}VIP会员。只有当您有意愿且成功支付会员服务费后，才可享受对应支付套餐下的会员服务。如您拒绝支付相关会员套餐费用或不同意本协议任一条款，则不能使用相应的VIP会员权益。
    </p>
    <p>
      （4）无论用户通过何种渠道申请成为{{ appName }}VIP会员，均应详细阅读并同意接受本协议约束。
      <b>
        {{ companyAlias }}从未授权任何第三方单位或个人销售、转让{{ appName }}VIP会员资格
        ，任何第三方单位或个人销售{{ appName }}VIP会员服务的均属于非法销售，{{ companyAlias }}有权追究其法律责任。
      </b>
    </p>
    <p>
      （5）您不得以盗窃、利用系统漏洞等非法途径以及在未获{{ appName }}授权的非法销售{{ appName }}VIP会员的平台或渠道上获取或购买会员服务，否则“{{ appName }}”平台有权取消您的{{ appName }}VIP会员资格。由此引发的法律责任由您自行承担，{{ companyAlias }}保留追究责任的权利。
    </p>
    <h4>2.2 会员权益</h4>
    <p>
      （1）{{ appName }}VIP会员权益，即“{{ appName }}”平台为VIP会员提供的特权服务，包括但不限于浏览音乐页免广告、开屏免广告、VIP尊贵标识、会员专属提现通道、会员无门槛提现卡券等。您理解并同意，鉴于{{ appName }}VIP会员类型的多样性、使用用户设备终端的多样性，不同类型的用户对应的会员权益可能有所差异。同时，我们有权根据市场情况对会员权益、使用的用户设备终端进行全部或部分的变更、取消、增加。您可以获取的会员权益以{{ appName }}客户端“我的-会员”页面的说明为准或{{ appName }}实际提供的服务为准，“{{ appName }}”平台有权基于自身业务发展需要变更部分或全部会员权益。就前述权益调整，“{{ appName }}”平台将在相应服务页面进行通知或公告；
    </p>
    <p>
      <b>
        （2）为免歧义，“{{ appName }}”平台为您提供的免广告的会员权益仅适用于开屏广告及音乐页面内的推荐广告。{{ appName }}会根据平台运营策略或应合作方的要求，仍可能会向您呈现不同种类的广告服务，且不排除{{ appName }}以其他方式投放的广告或商业信息，您在使用会员服务的过程中，可能接触到以各种方式投放的商业性广告高或商业信息。请您知悉并确认。
      </b>
    </p>
    <h4>2.3 服务期限</h4>
    <p>
      （1）本服务的期限以您自行选择并成功支付相应服务费用的期限为准，自缴费会员开通之日起生效，一旦您成为{{ appName }}VIP会员，即视为您认可对应会员服务套餐对应的价格。您可在“我的-会员”查看会员有效期间，或点击“我的-会员-立即续费”查看已开通会员的相关权益。您理解、知悉并同意，本服务的服务期间，包含“{{ appName }}”平台解决故障、服务器维修、调整、升级、因第三方侵权处理等所需的合理时间，对上述情况所需使用的时间{{ companyAlias }}不另行补偿；
    </p>
    <p>
      （2）{{ appName }}VIP会员提供的浏览音乐页免广告、开屏免广告、VIP尊贵标识、会员专属提现通道、会员无门槛提现卡券等服务都有固定的会员服务期限，您一旦成为{{ appName }}VIP会员即视为认可其服务期限；
    </p>
    <p>
      （3）您开通{{ appName }}VIP会员后可享受的全部权益以我们官方渠道公布的会员权益为准，我们有权基于自身业务发展需要变更全部或部分会员权益。就前述权益调整，我们将在相应服务页面进行通知或公告，同时我们也鼓励您定期查看本协议内容，以更好地保障您的权益；
    </p>
    <p>
      （4）会员服务的服务期限以会员自行选择并支付相应的会员费用的期限为准。会员服务期限到期后，我们将停止继续向您提供{{ appName }}VIP会员服务；
    </p>
    <p>
      （5）我们致力于不断改善自身服务，力求为您提供愈加优质、性价比更高的产品与服务。{{ appName }}VIP会员服务的收费标准由我们根据自身运营策略独立决定（包括VIP会员类型、会员等级、后续可能的资费调整等），并在会员页面或弹窗向您展示。您完成支付或继续使用VIP会员服务等行为，即视为您知悉并同意该收费标准；
    </p>
    <p>
      （6）会员服务仅限于申请账号自行使用。会员服务期内不能在“{{ appName }}”平台不同帐号之间转移，当前会员服务只支持安卓手机，IOS内会员服务暂未开通；
    </p>
    <p>
      （7）禁止赠与、借用、作用、转让或售卖会员资格。否则我们有权在未经通知的情况下取消转让账户、受让账户的会员服务资格，由此带来的损失由您自行承担。
    </p>
    <h4>2.4 会员使用规则</h4>
    <p>
      （1）若会员的行为持续违反本协议或违反国家相关法律法规，或{{ companyAlias }}认为会员行为有损{{ companyAlias }}或他人的声誉及利益，{{ companyAlias }}有权取消该会员的会员资格而无须给与任何补偿。
    </p>
    <p>（2）被取消会员资格的会员，将不再享有会员权利。</p>
    <p>
      （3）{{ companyAlias }}提供的会员服务内容不得用于商业、盈利等用途，只可供私人阅读使用，由此引发的争议或纠纷，{{ companyAlias }}不承担任何责任。
    </p>
    <p>
      （4）除非{{ companyAlias }}提前书面许可，您不得利用{{ appName }}VIP会员服务进行复制、下载、发行、销售、展示、传播会员服务提供的内容；禁止以商业目的使用会员服务提供的内容，包括但不限于下列情况：
    </p>
    <p>
      （5）您不得通过非法手段对{{ appName }}VIP会员账户的服务期限、消费金额、交易状态进行修改，或用非法的方式或为了非法的目的使用已购买的会员服务提供的内容；
    </p>
    <p>（6）您不得将您的会员账户有偿或无偿提供给任何第三人，并允许他人通过您的账户使用{{ appName }}VIP会员服务；</p>
    <p>（7）您不得将会员服务提供的内容复制、销售、出租或授权给任何第三方；</p>
    <p>
      （8）您不得主动对{{ companyAlias }}用于保护会员服务提供的任何安全措施技术进行破解、更改、反操作、破坏或其他篡改，或协助他人进行上述行为；
    </p>
    <p>
      （9）您不得采用收费或免费的方式，全部或部分在任何公开场合展示{{ appName }}VIP会员服务提供的内容，除非您的上述行为不会构成侵权；
    </p>
    <p>
      （10）您不得损害第三方利益，通过{{ appName }}收集第三方资料信息，破坏或盗取第三方账号，发送诈骗邮件和垃圾邮件等非法信息，侵犯第三方合法权益，包括但不限于隐私权、个人信息权益、知识产权、财产权利等。
    </p>
    <h3>3.双方权利和义务</h3>
    <h4>3.1 违约行为</h4>
    <p>
      您在使用本服务得过程中，不得进行以下违约行为：<br />
      （1）违反本协议约定的；<br />
      （2）违反法律、法规、规章、条例以及任何具有法律效力之规范规定的；<br />
      （3）破坏、影响{{ companyAlias }}对任何第三方提供本服务的；<br />
      （4）进行危害计算机网络安全的行为；<br />
      （5）对{{ companyAlias }}及关联第三方（包括授权方和合作方）提供的本协议服务、活动造成不良影响，犯及/或关联第三方及/或其他用户的正当利益的；<br />
      （6）被有关部门认定侵犯任何第三方的合法权益的；<br />
      （7）利用本服务获取非法利益的，包括但不限于通过转售、转让、转授权等行为不正当牟利；<br />
      （8）其他侵犯{{ companyAlias }}合法利益的行为。
    </p>
    <h4>3.2违约行为处理</h4>
    <p>
      {{ companyAlias }}有权对会员遵守本协议的情况进行检查、监督、提示，如发现会员的行为违反有关法律法规或违反本协议约定的情况，{{ companyAlias }}享有要求其纠正及追究其责任等权利。
    </p>
    <h3>4.免责声明</h3>
    <p>
      4.1{{ companyAlias }}向会员提供的全部服务，均仅限于会员在“{{ appName }}”平台上使用，任何以恶意破解等非法手段将“{{ appName }}”平台提供的服务内容与“{{ appName }}”平台分离的行为，均不属于本协议中约定的{{ companyAlias }}提供的服务。由此引起的一切法律后果由行为人负责，{{ companyAlias }}将依法追究行为人的法律责任。
    </p>
    <p>
      4.2您明确了解并同意，{{ appName }}VIP会员的付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利用您账户或银行卡等有价卡等进行违法活动，该等风险均会给您造成相应的经济损失。您应自行承担向侵权方追究侵权责任和追究责任不能的后果。
    </p>
    <h3>5.服务的中止、终止及变更</h3>
    <h4>5.1 变更</h4>
    <p>
      <b>
        （1）{{ companyAlias }}有权依据本协议的约定变更服务内容、服务方式以及本用户协议内容的，如果会员不同意{{ companyAlias }}的变更，有权选择取消并停止使用已经获取的对应的全部或部分服务；如果会员继续使用{{ companyAlias }}提供的上述服务，则视为会员已经接受{{ appName }}的上述调整。
      </b>
    </p>
    <p>
      <b>
        （2）{{ companyAlias }}对会员服务协议一旦进行修改，将在相关页面上提示修改的内容。一经提示即视为会员已阅读、理解、接受修改后的内容。会员如果不同意服务协议条款的修改，不影响您在原来购买的会员套餐项下享有的会员权益，您可在有效期内继续使用VIP会员权益。
      </b>
    </p>
    <h3>5.2 中止和终止</h3>
    <p>
      <b>
        （1）因发生不可抗力的事由，如政策因素、政府行为、自然灾害等，导致VIP会员服务无法继续，{{ companyAlias }}会以最快的速度通知会员，但不承担由此对会员造成的任何损失，您已经使用的会员权益对应的服务费将不予退还。
      </b>
    </p>
    <p>
      <b>
        （2）如会员违反或被视为违反本服务条款中的内容，{{ companyAlias }}有权在不通知会员的情况下立即终止您已购买的所有服务，以及取消您的{{ appName }}VIP会员权益，且不退还任何已缴纳的会员服务费用。
      </b>
    </p>
    <p>
      <b>
        （3）{{ companyAlias }}未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。{{ companyAlias }}随时有权要求您继续履行义务并承担相应的违约责任。
      </b>
    </p>
    <h3>6.未成年人使用条款</h3>
    <p>
      6.1
      若您未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用付费会员服务，并且已经获得监护人之同意。若未成年人用户支付了会员费用，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可
    </p>
    <p>
      6.2 未成年人用户在使用付费会员服务时应注意以下事项，提高安全意识，加强自我保护：<br />
      （1）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；<br />
      （2）在监护人或老师的指导下，学习正确使用网络；<br />
      （3）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
    </p>
    <p>
      6.3
      监护人、学校均应对未成年人使用付费会员服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，引导未成年人健康、和谐的使用本服务。
    </p>
    <p>
      6.4
      为了最大化保障用户个人信息权益，{{ companyAlias }}仅基于最小必要原则采集用户的个人信息，因此，无法准确判断{{ appName }}VIP会员的用户是否属于未成年人用户；若未成年人用户支付了会员费用，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。若监护人对此提出异议的，可与我们的客服取得联系，我们将为您提供妥善的解决方案。
    </p>
    <h3>7.其他</h3>
    <h4>7.1 协议生效</h4>
    <p>自您同意开通会员并完成会员费用支付之日起，您与{{ companyAlias }}共同受到本协议约束。</p>
    <h4>7.2 条款标题</h4>
    <p>本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释</p>
    <h4>7.3 条款效力</h4>
    <p>本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
    <h4>7.4 通知</h4>
    <p>
      本协议项下{{ companyAlias }}对于用户的所有通知均可通过站内消息、客户端推送、公众号通知等方式进行，该等通知于发布之日起视为已送达用户。
    </p>
    <h4>7.5 联系方式</h4>
    <p>
      <b>
        如您对本协议或使用{{ appName }}的VIP会员服务相关的事宜有任何问题（包括问题咨询、投诉等），您可以通过【我的】-【反馈与帮助】-【意见反馈】、添加微信号kuaiyin-C{{ appName }}小助手在微信内进行反馈或者通过邮件kuaiyinkf@rings.net.cn进行反馈，{{ companyAlias }}会在收到您的意见和反馈后尽快向您回复。
      </b>
    </p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          [
            'kuaiyintingge',
            { appName: '快音听歌版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          [
            'kuaiyinjisu',
            { appName: '快音极速版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          ['kuaiyinyue', { appName: '快音悦', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          ['kuaiyinfm', { appName: '快音FM', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
        ]),
        companyAlias: '',
        companyName: '',
      };
    },
    mounted() {
      let app_name = 'kuaiyin';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;
      this.companyName = this.appNameMap.get(app_name).companyName;
      this.companyAlias = this.appNameMap.get(app_name).companyAlias;

      document.title = `${this.appName}VIP会员服务协议`;
    },
  };
</script>
<style lang="less" scoped>
  .protocol-box {
    box-sizing: border-box;
    padding: 0.46rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.46rem;
  }
  p {
    margin-bottom: 0.4rem;
  }
</style>
